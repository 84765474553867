import React, { useMemo } from 'react';
import classnames from 'classnames';
import Image from '../Image/Image';
import Ribbon from '../product-list/ribbon';
import { InternalLink } from '../form-elements/Link';
import Currency from '../../../helpers/currency';
import { useSelector } from 'react-redux';
import { markenStandortSelector } from '../../../modules/selectors/standort/markenStandort';

function ProductCard({ product, forOffer }) {
    //console.log(product);
    const to = useMemo(() => {
        return product.href || `/${product.categories[0]}/${product.id}`;
    }, [product]);
    const markenStandort = useSelector(markenStandortSelector)
    const isNoa = markenStandort.gesellschafterNr === "035";
    let isOfferActive = product.isDeliveryOfferActive || product.isPickUpOfferActive;
    return (
        <div className="uk-card uk-card-default uk-card-small uk-flex uk-flex-column">
            <div className="uk-card-media-top uk-flex uk-flex-center uk-flex-middle" style={{ height: 235 }}>
                <Image src={product.imagelink} alt={product.bezeichnung} resizeHeight={235} fit="cover" />
            </div>
            {forOffer && <span className='artikelnummer'>Artikelnummer: {product.eigeneArtikelVarianten? product.eigeneArtikelVarianten[0]?.shownId : product.id}</span>}
            <div className="uk-card-body uk-flex uk-flex-column uk-flex-1">
                <Ribbon product={product} isOfferActive={isOfferActive}/>
                <InternalLink to={to} modifier="reset">
                    <h4 className="uk-card-title cardTitle">{product.bezeichnung}</h4>
                </InternalLink>
                <div className="uk-margin-auto-top">
                    <h4
                        className={classnames('uk-card-title', {
                            'uk-text-danger': product.soldOut || (product.promotion && product.promotion !== 'none'  && isOfferActive),
                        })}
                    >
                        <div className="promotionalPriceBox">
                            <div className="promotionalPrice">
                                {
                                    (product.promotion !== "none" && product.originalPrice && isOfferActive) ? 
                                        <span className={isNoa ? "noa-price" : ""}><Currency quantity={product.originalPrice} /> </span>
                                    : 
                                        <></>
                                }
                            </div>
                            <span className={isNoa ? "noa-price" : ""}><Currency quantity={(product.preisAb || product.vk_preis)} /> </span>
                        </div>
                    </h4>
                    <InternalLink to={to} fullWidth modifier="primary">
                        ZUM PRODUKT
                    </InternalLink>
                </div>
            </div>
        </div>
    );
}

export default ProductCard;
