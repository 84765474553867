import React from 'react';

function Ribbon({ product, isOfferActive }) {
    if(!product.soldOut && 
        (!product.promotion || product.promotion === 'none' ||  
            (product.originalPrice && product.preisAb && product.originalPrice === product.preisAb)  || 
            !isOfferActive || ((product.aktionsPreis && product.aktionsPreis >= product.vk_preis) && 
            (product.aktionsPreisPickup && product.aktionsPreisPickup >= product.pickup_preis))
        ) 
        
    ) {
        return null;
    }

    return (
        <div className="aps-ribbon aps-ribbon-top-left uk-position-z-index uk-position-top-left">
            <div style={{ top: 27, right: -25 }}>
                {product.soldOut && (
                    <>
                        <div className="uk-text-small">Zur Zeit</div>
                        <div className="uk-text-bold">nicht lieferbar</div>
                    </>
                )}
                {!product.soldOut && product.promotion !== 'none' && <span className="uk-text-bold">ANGEBOT</span>}
                {!product.soldOut && product.promotion === 'some' && <div className="uk-text-small">für ausgewählte Produkte</div>}
            </div>
        </div>
    );
}

export default Ribbon;
